<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Az önszeretet alapja
          </h2>
          <p class="hashText">- a mentális és érzelmi jólétünk alapja </p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">

                <p class="text">
                Az önszeretet nem csak a mentális és érzelmi jólétünk alapja, hanem a boldogságunk kulcsa is. A digitális világ és az okostelefonok korában különösen fontos, hogy ne engedjük, hogy a közösségi média vagy a folyamatos online jelenlét aláássa önbecsülésünket. </p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/selflove.jpg" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
            <b>Tippek az önszeretet fejlesztésére az online térben:</b>
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b> Korlátozd a közösségi média használatát:  </b> Tudd meg, hogy mikor érzel stresszt vagy összehasonlítást a közösségi média miatt, és próbáld meg csökkenteni az időt, amit ezekkel a platformokkal töltesz. Az okostelefonok segítenek abban, hogy rugalmasan kezeljük a kapcsolatainkat, de fontos, hogy ne hagyjuk, hogy a külső elvárások határozzák meg önértékelésünket.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b> Gyakorolj pozitív megerősítést: </b> Az okostelefonok segíthetnek abban, hogy emlékeztessük magunkat a napi pozitív gondolatokra. Használhatsz alkalmazásokat, amelyek önértékelésed javítására irányulnak, mint például a meditációs vagy napi aforizmák applikációi.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Tarts szüneteket: </b>Az online tér folyamatos jelenléte miatt fontos, hogy tartsunk szüneteket. Az okostelefonok segítségével akár beállíthatunk időzítőket, hogy emlékeztessenek a pihenésre és az offline pillanatok fontosságára.</p>
                </li>
              </ul>
            </p>
            <p class="text">A Samsung és az Együtt a bántalmazás ellen projekt célja, hogy tudatosítsuk: az önszeretet és az egészséges kapcsolatok nemcsak a valóságban, hanem az online világban is egyaránt fontosak.</p>
            </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="true"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Az önszeretet alapja", 
        desc: "Hogyan építsünk egészséges kapcsolatot önmagunkkal a digitális világban", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Az önszeretet alapja",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Hogyan építsünk egészséges kapcsolatot önmagunkkal a digitális világban"        
    })
  }
}
</script>